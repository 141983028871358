html {
	scroll-behavior: smooth;
}
.hidden {
	position: absolute;
	left: -100vw;
}
h5 {
	font-size: calc(1.15 * 1rem);
	margin-bottom: 0;
}
h4 {
	text-transform: uppercase;
}
blockquote {
	border-left: 5px solid #dadada;
	padding: 1px 10px 1px 20px;
	font-size: 2rem;
}
.link-custom {
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-decoration-color: currentColor;
	text-decoration-thickness: 1px;
	text-underline-offset: 0.2em;
	text-decoration-skip: auto;
	text-decoration-skip-ink: auto;
}
/* accordion w/bk link */
.sqs-background-enabled.sqs-block-accordion .accordion-item__description a {
	color: var(--tweak-heading-medium-color-on-background);
}

/* Menu/pricing */
#collection-63d423f8bf8be34926e7e2c6 .sqs-block-content p {
	margin-top: 0;
}

.select.select-category {
	display: none;
}
.absolute {
	position: absolute;
}
.absolute-nav {
	font-size: 12px;
	margin-right: 2em;
	background: rgba(255, 255, 255, 0.5);
	color: #000;
	padding: 0 2em;
	opacity: 0.5;
}
.absolute-nav p a {
	color: #000;
}
.top {
	top: 0;
}
.right {
	right: 0;
}
.sqs-block-newsletter .newsletter-form-button {
	background-color: #000 !important;
	border: 1px solid #887f8f !important;
	color: #887f8f !important;
}
/* MOBILE ONLY */
@media only screen and (max-width: 480px) {
	#footer-sections .sqs-html-content * {
		text-align: center !important;
	}
	.newsletter-block .newsletter-form-wrapper--alignLeft .newsletter-form {
		text-align: center;
	}
	.newsletter-block
		.newsletter-form-wrapper--alignLeft
		.newsletter-form-button-wrapper,
	.newsletter-block
		.newsletter-form-wrapper--alignLeft
		.newsletter-form-button {
		width: auto;
		min-width: 18rem;
	}
}
.newsletter-block .newsletter-form-field-element {
	background: #000;
	color: #fff;
	border-bottom: 1px solid #908798;
	padding: 0.5em !important;
}

.newsletter-block .newsletter-form-field-element:focus {
	background: #000 !important;
}
.newsletter-block .newsletter-form-field-element::placeholder {
	color: #887f8f;
	text-transform: uppercase;
}

@media screen and (min-width: 1296px) {
	.newsletter-form-wrapper .newsletter-form .newsletter-form-field-element {
		padding: 0.5em !important;
		background: black;
		font-family: futura-pt;
		font-size: 16px !important;
		font-style: normal;
		font-weight: 400;
		text-decoration-color: #908798;
		border-bottom: 1px solid #908798;
		color: #fff;
	}
}
/* Archive block */
@media screen and (min-width: 768px) {
	ul.archive-group-list {
		display: flex;
		justify-content: space-around;
		color: #ababab;
	}
	.archive-group-name-link:hover {
		color: #fff;
	}
	/* talent archive gallery */
	.sqs-gallery-design-grid-slide .margin-wrapper {
		display: flex;
		flex-direction: column-reverse;
	}

	.sqs-gallery-design-grid-slide .image-slide-title {
		font-size: 24px !important;
		padding-bottom: 2vw;
	}
}

.overlay-link {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: hsla(0, 0%, 0%, 29%);
}
.overlay-link:hover {
	background-color: transparent;
}
/* START Phorest embed css 
LINK: https://support.phorest.com/hc/en-us/articles/360017444299-How-to-embed-your-Online-Booking-page-as-an-iFrame-into-a-responsive-website
*/
.iframe-container {
	position: relative;
	height: 800px;
	overflow: hidden;
}
.iframe-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
/* END Phorest embed css */
/* Services Toggle */
.show {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s linear 0s, opacity 300ms;
}

.fe-block-yui_3_17_2_1_1689695997826_3663 {
	visibility: visible;
	opacity: 0;
	transition: visibility 0s linear 300ms, opacity 300ms;
}
/* END Services Toggle */

.header
	.header-layout-nav-right
	.header-nav
	.header-nav-item--folder
	.header-nav-folder-content {
	left: 0;
	right: unset;
	text-align: left;
}
.header-nav-folder-item {
	padding: 0.5em 0;
	cursor: pointer;
}
.summary-item {
	position: relative;
}
.summary-content.sqs-gallery-meta-container {
	position: absolute;
	z-index: 1;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.summary-thumbnail-image {
	filter: grayscale(1);
}
.summary-metadata-item a {
	text-transform: uppercase;
	font-size: 16px;
}
.summary-title-link {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	text-align: center;
	padding-top: 50%;
	font-size: 31px;
}

.summary-title {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.summary-item:hover > .summary-content {
	opacity: 1;
	background-color: rgba(0, 0, 0, 0.5);
}
.menu-block .menu-selector label {
	padding: 0.5em;
}

.menu-block .menu-selector label.menu-select-labels--active {
	background-color: #000;
	color: #fff;
	text-decoration: none;
}
/*
  .menu-block .menu-style-classic .menu-selector {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    row-gap: 1em;
}
  */
